<template>
  <div class="container mt-10" id="tasksPage">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">
        
        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>
        <div class="filter-item">
          <CustomerSelectbox :selectedId="filter.CustomerId" @changed="(id) => filter.CustomerId = id"
            :key="filter.CustomerId" />
        </div>
        <div class="filter-item">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id"
            :key="filter.ProjectId" />
        </div>
        <div class="filter-item">
          <MultipleEmployeeSelectboxTask :selectedId="filter.AssigneeIds" @changed="(id) => filter.AssigneeIds = id" />
        </div>
        <div class="filter-item">
          <ProjectTaskStatusSelectbox :nullable="true" :selectedId="filter.Status"
            @changed="(id) => filter.Status = id" />
        </div>
        <div class="filter-item">
          <ProjectTaskPrioritySelectbox :nullable="true" :selectedId="filter.Priority"
            @changed="(id) => filter.Priority = id" />
        </div>

        <div class="filter-item pl-2">
          <el-dropdown>
            <span class="el-dropdown-link cursor-pointer">
              <i class="fas fa-sort-amount-down-alt"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="filter.SortBy = item.Key" v-for="item in $getEnums('ProjectTaskSorting').filter(x => filter.Status == 1 ? true : [0,1,2,3].some(y => y == x.Key))" :key="item.Key">
                <i class="fas fa-check" v-if="filter.SortBy == item.Key"></i> {{ item.DisplayName }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="filter-item ml-2">
          <el-switch v-model="filter.AssignedToMe" active-text="Assigned to me"></el-switch>
        </div>

      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData(); newTaskCreated = 0}"
          :loading="loading == 'getData'"></el-button>
      </el-tooltip>

    </div>

    <div class="card p-5" v-if="!tasks">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="tasks">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ tasks.TotalCount }} tasks(s) here.</h3>
          </div>
          <div class="card-actions">

            <el-tooltip class="item" effect="dark" content="Sync data" placement="top" v-if="newTaskCreated > 0">
              <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData(); newTaskCreated = 0}"
                :loading="loading == 'getData'">{{ newTaskCreated }} new task(s)</el-button>
            </el-tooltip>
            
            <el-button type="primary" icon="fas fa-user-friends" @click="addNewTask" round>Add new task</el-button>
          </div>
        </div>

        <div class="activity-table">
          <NoData v-if="tasks.Items.length == 0" />
          <el-table :data="tasks.Items" v-if="tasks.Items.length > 0" ref="tab" @row-click="expand"
            v-loading="loading == 'getData'" class="tasks-table cursor-pointer">

            <el-table-column label="Task" class="">
              <template slot-scope="scope">
                <p class="task-list-title">
                  <el-tooltip class="item" effect="dark" content="Synced from Asana" placement="top"
                    v-if="scope.row.Source == 1">
                    <img class="integration-icon" src="@/assets/img/integrations/asana_icon.png" />
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Synced from Clickup" placement="top"
                    v-if="scope.row.Source == 2">
                    <img class="integration-icon" src="@/assets/img/integrations/clickup_icon.png" />
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Synced from Monday" placement="top"
                    v-if="scope.row.Source == 3">
                    <img class="integration-icon" src="@/assets/img/integrations/monday_icon.png" />
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Synced from Jira" placement="top"
                    v-if="scope.row.Source == 4">
                    <img class="integration-icon" src="@/assets/img/integrations/jira_icon.png" />
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Synced from Trello" placement="top"
                    v-if="scope.row.Source == 5">
                    <img class="integration-icon" src="@/assets/img/integrations/trello_icon.png" />
                  </el-tooltip>
                  {{ scope.row.Title }}

                  <i class="far fa-file-alt" v-if="scope.row.Details != null && scope.row.Details.length > 0"></i>
                </p>
                <p v-if="scope.row.Project" class="task-project">
                  <i class="fas fa-circle" :style="'color: ' + scope.row.Project.Color"></i> {{ scope.row.Project.Title
                  }}

                  <el-tooltip class="item" effect="dark" content="Task due date" placement="top"
                    v-if="scope.row.DueDate != null">
                    <span :class="moment(scope.row.DueDate) < new Date() && scope.row.Status == 0 ? 'text-danger' : ''">
                      <i class="fas fa-clock ml-3"></i> {{ moment(scope.row.DueDate).format('DD MMM yyyy HH:mm').replace('00:00','') }}
                    </span>
                  </el-tooltip>
                  <span v-else>
                    <i class="fas fa-clock ml-3"></i> No due date
                  </span>

                  <span v-if="scope.row.NonBillable == true" class="text-danger">
                    <i class="fas fa-dollar-sign ml-3"></i> Billing disabled
                  </span>
                </p>
              </template>
            </el-table-column>

            <!-- <el-table-column type="expand">
            <template slot-scope="props">
              <div style="padding:10px;">
                <p class="mb-2"><b>{{ props.row.Title }}</b></p>
                <div class="mb-2" style="overflow: auto; max-height: 300px;" v-html="props.row.Details"></div>
                <p>Created at: {{ moment(props.row.CreateDate).format('DD MMMM yyyy HH:mm') }}</p>
              </div>

            </template>
          </el-table-column>  -->

            <el-table-column label="" width="130" class-name="text-center">
              <template slot-scope="scope">
                <UserSelect :ids="scope.row.AssigneeIds" :id="scope.row.ID"
                  :position="tasks.Items.length - 3 > scope.$index ? 'top' : 'bottom'" v-if="teams && employees"
                  :key="scope.row.ID + scope.row.AssigneeIds.length + '-assignees'" />

              </template>
            </el-table-column>

            <el-table-column label="Status" width="145" class-name="text-center">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link badge cursor-pointer" :class="getStatusBg(scope.row.Status)"
                    @click.stop>
                    {{ $getEnumTitle('ProjectTaskStatus',scope.row.Status) }} <i
                      class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="changeStatus(scope.row.ID, item.Key)"
                      v-for="item in $getEnums('ProjectTaskStatus')" :key="scope.row.ID + '-Status-' + item.Key"
                      :disabled="item.Key == scope.row.Status">
                      <i class="fas fa-circle" :class="'text-' + getStatusBg(item.Key)"></i>
                      {{ item.DisplayName }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>

            <el-table-column label="Priority" width="75" class-name="text-center">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link cursor-pointer" @click.stop>
                    <i class="fa fa-flag " :class="'text-' + getPriorityBg(scope.row.Priority)"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="changePriority(scope.row.ID, item.Key)"
                      v-for="item in $getEnums('ProjectTaskPriority')" :key="scope.row.ID + '-Priority-' + item.Key"
                      :disabled="item.Key == scope.row.Priority">
                      <i class="fa fa-flag " :class="'text-' + getPriorityBg(item.Key)"></i> {{ item.DisplayName }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>

            <el-table-column label="Time tracked" width="160" class-name="text-center">
              <template slot-scope="scope">
                <p class="badge">{{ $parseTime(scope.row.TimeTracked) }}</p>
              </template>
            </el-table-column>

            <el-table-column width="100">
              <template slot-scope="scope">
                <el-row>

                  <el-tooltip class="item" effect="dark" content="Show task" placement="top">
                    <el-button type="info" icon="fas fa-eye" size="small" circle
                      @click.stop="showTaskDrawer(scope.row)"></el-button>
                  </el-tooltip>

                  <!-- <el-tooltip class="item" effect="dark" content="Show task" placement="top">
                    <el-button type="info" icon="fas fa-eye" size="small" circle @click.stop="showTaskDrawer(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Edit task" placement="top">
                    <el-button type="info" icon="fas fa-pen" size="small" circle @click.stop="updateTask(scope.row)"></el-button>
                  </el-tooltip> -->

                  <el-tooltip class="item" effect="dark" content="Delete task" placement="top">
                    <el-button type="danger" icon="fas fa-trash" size="small" circle @click.stop="deleteTask(scope.row)"
                      :loading="loading == 'delete-' + scope.row.ID"></el-button>
                  </el-tooltip>

                </el-row>
              </template>
            </el-table-column>

          </el-table>

          <Pagination :Page="filter.Page" :PageSize="filter.PageSize" :Total="tasks.TotalCount"
            @sizeChanged="(args) => { filter.PageSize = args }" @pageChanged="(args) => { filter.Page = args }" />

        </div>

      </div>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" :title="taskModalTitle" :visible.sync="taskAddUpdateModal"
      center modal-append-to-body destroy-on-close>
      <TasksAddUpdateModal @taskCreated="taskCreated" @closeDialog="taskAddUpdateModal = false" :data="addUpdateTask"
        v-if="taskAddUpdateModal" />
    </el-dialog>

    <el-drawer class="profile-drawer" :visible.sync="showTask" type="primary" :direction="'rtl'" :withHeader="false"
      append-to-body destroy-on-close :show-close="false" :close-on-press-escape="false" size="40%">
      <TaskDetail @closeDialog="showTask = false" v-if="showTask"
        :key="'show-task-' + (selectedTask != null ? selectedTask.ID : 'null')" :data="selectedTask" />
    </el-drawer>


  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import TasksAddUpdateModal from './TasksAddUpdateModal.vue';
import moment from "moment/min/moment-with-locales";

import Pagination from '@/components/Pagination';
import UserSelect from '@/components/UserSelect';
import TaskDetail from '@/components/TaskDetail';

import MultipleEmployeeSelectboxTask from '@/components/form/MultipleEmployeeSelectboxTask.vue';
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import CustomerSelectbox from '@/components/form/CustomerSelectbox.vue';
import ProjectTaskStatusSelectbox from '@/components/form/ProjectTaskStatusSelectbox.vue';
import ProjectTaskPrioritySelectbox from '@/components/form/ProjectTaskPrioritySelectbox.vue';
import SearchInput from '@/components/form/SearchInput.vue';


export default {
  name: 'TasksIndex',
  components: {
    Breadcrumb,
    TaskDetail,
    TasksAddUpdateModal,
    ProjectSelectbox,
    CustomerSelectbox,
    MultipleEmployeeSelectboxTask,
    Pagination,
    UserSelect,
    ProjectTaskStatusSelectbox,
    ProjectTaskPrioritySelectbox,
    SearchInput
  },
  data() {
    return {
      loading: null,
      showTask: false,
      moment,
      newTaskCreated:0,
      selectedTask: null,
      meta: {
        title: 'Tasks',
        desc: 'You can manage your tasks here.'
      },
      filter: {
        AssignedToMe: false,
        SearchTerm: null,
        CustomerId: null,
        ProjectId: null,
        AssigneeIds: [],
        Priority: null,
        Status: null,
        Page: 1,
        PageSize: 24,
        SortBy:0,
      },
      taskAddUpdateModal: false,
      taskModalTitle: 'Add new task',
      addUpdateTask: {
        Id: null,
        ProjectId: null,
        Title: null,
        Details: null,
        Source: null,
        OrderNo: 1,
        AssigneeIds: [],
        Priority: null,
        DueDate: null,
        Status: 0,
        ExternalTaskId: null,
        NonBillable: false,
      }
    }
  },
  async mounted() {

    Promise.all([
      await this.$store.dispatch("taskTeams"),
      await this.$store.dispatch("taskEmployees")
    ]);

    this.$setMeta(this.meta);
    this.getData();

    //65e83fce8528fbe77b6564ac
    if (this.$route.query.viewTask) {
      setTimeout(() => {
        this.showTaskDrawer({
          ID: this.$route.query.viewTask,
        });
      }, 1250);
    }

    if (this.$route.query.createTask) {
      setTimeout(() => {
        this.addNewTask();
      }, 1250);
    }


    this.$socket.on("presence-user-" + this.account.User.ID + "-taskTitleChanged", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
              if(index != -1)
              {
                this.tasks.Items[index].Title = tempData.Title;
              }
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-dueDateChanged", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
              if(index != -1)
              {
                this.tasks.Items[index].DueDate = tempData.DueDate;
              }
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-statusChanged", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
              if(index != -1)
              {
                this.tasks.Items[index].Status = tempData.Status;
              }
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-priorityChanged", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
              if(index != -1)
              {
                this.tasks.Items[index].Priority = tempData.Priority;
              }
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-assigneesChanged", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
              if(index != -1)
              {
                this.tasks.Items[index].AssigneeIds = tempData.AssigneeIds;
                this.tasks.Items[index].Assignees = tempData.Assignees;
              }
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-taskDeleted", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            console.log("taskDeleted", tempData);
            if(this.tasks != null)
            {
              this.tasks.Items = this.tasks.Items.filter(x => x.ID != tempData.Id);
            }
        }
    });

    this.$socket.on("presence-user-" + this.account.User.ID + "-newTaskCreated", data => {
        if (data != null && data != undefined) {
            var tempData = data.data;
            if(this.tasks != null)
            {
              setTimeout(() => {
                var index = this.tasks.Items.findIndex(x => x.ID == tempData.Id);
                if(index == -1)
                {
                  this.newTaskCreated += 1;
                }
              },500);
            }
        }
    });

  },
  beforeDestroy() {
    this.$socket.off("presence-user-" + this.account.User.ID + "-taskTitleChanged");
    this.$socket.off("presence-user-" + this.account.User.ID + "-dueDateChanged");
    this.$socket.off("presence-user-" + this.account.User.ID + "-statusChanged");
    this.$socket.off("presence-user-" + this.account.User.ID + "-priorityChanged");
    this.$socket.off("presence-user-" + this.account.User.ID + "-assigneesChanged");
    this.$socket.off("presence-user-" + this.account.User.ID + "-taskDeleted");
    this.$socket.off("presence-user-" + this.account.User.ID + "-newTaskCreated");
  },
  methods: {
    addNewTask() {
      this.selectedTask = null;
      this.showTask = true
    },
    showTaskDrawer(item) {
      this.selectedTask = item;
      this.showTask = true;
    },
    async changeStatus(id, status) {
      await this.$store.dispatch("updateTaskStatus", {
        Id: id,
        Status: status
      });
    },
    async changePriority(id, priority) {
      await this.$store.dispatch("updateTaskPriority", {
        Id: id,
        Priority: priority
      });
    },
    getStatusBg(status) {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "green";
        case 2:
          return "red";
        case 3:
          return "purple";
        case 4:
          return "orange";
        case 5:
          return "yellow";
        default:
          return "";
      }
    },
    getPriorityBg(priority) {
      switch (priority) {
        case 0:
          return "blue";
        case 1:
          return "green";
        case 2:
          return "orange";
        case 3:
          return "red";
        default:
          return "";
      }
    },
    expand(row, isExpanded) {
      this.selectedTask = row;
      this.showTask = true;

      // this.showTask(row);
      // if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
      // {
      //   this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
      // }
      // else
      // {
      //   this.$refs.tab.store.states.expandRows.push(row);
      // }
    },
    async taskCreated() {
      this.getData();
    },
    async getData() {
      this.loading = 'getData';
      await this.$store.dispatch("getTasks", this.filter)
      this.loading = null;
    },
    addtask() {
      this.taskModalTitle = 'Add new task';
      this.addUpdateTask = {
        Id: null,
        ProjectId: null,
        Title: null,
        Details: null,
        Source: 0,
        OrderNo: 1,
        AssigneeIds: [],
        Priority: null,
        DueDate: null,
        Status: 0,
        ExternalTaskId: null,
        NonBillable: false,
      };
      this.taskAddUpdateModal = true;
    },
    updateTask(item) {
      this.taskModalTitle = 'Update task';
      this.addUpdateTask = {
        Id: item.ID,
        ProjectId: item.ProjectId,
        Title: item.Title,
        Details: item.Details,
        Source: item.Source,
        OrderNo: item.OrderNo,
        AssigneeIds: item.AssigneeIds,
        DueDate: item.DueDate,
        Priority: item.Priority,
        Status: item.Status,
        ExternalTaskId: item.ExternalTaskId,
        NonBillable: item.NonBillable != null ? item.NonBillable : false
      };
      this.taskAddUpdateModal = true;
    },
    deleteTask(item) {
      this.loading = 'delete-' + item.ID;

      this.$confirm('You are about to delete a task. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        var res = await this.$store.dispatch("deleteTask", { Id: item.ID });
        if (res != null) {
          await this.$store.dispatch("getTasks", this.filter);
        }
        this.loading = null;
      }).catch(() => {
        this.loading = null;
      });
    },
  },
  computed: {
    account() {
        return this.$store.getters.getAccount;
    },
    tasks() {
      return this.$store.getters.getTasks;
    },
    employees() {
      return this.$store.getters.getTaskEmployees;
    },
    teams() {
      return this.$store.getters.getTaskTeams;
    }
  },
  watch: {
    'filter.SortBy'() {
      console.log('filter.SortBy', this.filter.SortBy);
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }

    },
    'filter.SearchTerm'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.CustomerId'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.ProjectId'() {
      this.filter.CustomerId = null;
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.AssigneeIds'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.AssignedToMe'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.Status'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.Priority'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.Page'() {
      this.getData();
      this.$toTop("tasksPage");
    },
    'filter.PageSize'() {
      this.filter.Page = 1;
      this.getData();
      this.$toTop("tasksPage");
    }
  }
}
</script>
