<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
     
    <portal to="action-buttons">
      <el-button type="primary" round icon="fas fa-ticket-alt" @click="redeemCodeModal = true">Redeem code</el-button>
    </portal>

    <template v-if="sub">
      <div class="card p-7 mb-10 bg-blue" v-if="sub.Subscription">
      <div class="card-header">
        <div class="card-title">
          <h3>Your current subscription plan is "{{sub.Subscription.Plan.Title}}"</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">

        <template v-if="sub.Subscription.IsLifetime">
          <li>You have a <b>life-time access</b> to worktivity features.</li>
          <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
          <li>If you need more employees you have <b>%50 OFF</b> for all plans. You can start a new subscription anytime.</li>
        </template>
        <template v-else>
          <template v-if="sub.Subscription.Plan.Id == '641adf5362535c09fc3d8422'">
            <li>Your free trial will be end at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b>.</li>
            <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
          </template>
          <template v-else>
            <template v-if="sub.Subscription.IsCancelled">
              <li>You subscription is cancelled.</li>
              <li>Your subscription will be end at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b>.</li>
            </template>
            <template v-else>
              <li>Your subscription will be renew at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b> and your primary card will be charged $<b>{{ (sub.Subscription.Plan.Price * (sub.Subscription.Users - (sub.Subscription.LifetimeFreeUsers != null ? sub.Subscription.LifetimeFreeUsers : 0))).toFixed(2) }}</b>.</li>
              <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
              <li>You can cancel your subscription plan any time by clicking <a href="javascript:;" @click="openCancelModal">here</a>.</li>
            </template>
          </template>
        </template>

        <li>You can change your subsciption plan any time by clicking <a href="javascript:;" @click="startNewSubscription = true">here</a>. Your current subscription will be cancelled automatically.</li>
        
        <li v-if="sub.Subscription.CustomDataRetentionMonths != null">Your data older than <b>{{ sub.Subscription.CustomDataRetentionMonths }} months</b> will be automatically deleted.</li>

      </ul>
    </div>
    <div class="card p-7 mb-10 bg-blue" v-else>
      <div class="card-header">
        <div class="card-title">
          <h3>You dont have any subscription plan</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">
        <li>If you have any questions, please feel free to <a href="/contact-us">contact us</a>.</li>
        <li>You need to start a subscription to use worktivity.</li>
      </ul>
    </div>

    <div class="card p-5 mb-10" id="start-subscription-page" v-if="plans && startNewSubscription">

      <div class="card-header hr mb-5">
        <div class="card-title">
          <h3>Start new subscription</h3>
        </div>
        <div class="card-actions">
          <el-button type="primary" size="medium" @click="openPricing" round>See details</el-button>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData">
        <div class="sub-item-conteiner mb-5">
          <div class="sub-item" :class="item.ID == formData.PlanId ? 'selected' : ''" v-for="item in plans.Items" :key="item.ID" @click="formData.PlanId = item.ID">
            <div class="sub-item-title">
              <h5>
                {{ item.Title }}
              </h5>
              <span>
                <span class="base-price" v-if="item.BasePrice != null">${{ item.BasePrice.toFixed(2) }}</span> ${{ item.Price.toFixed(2) }} per employee / month
              </span>
            </div>
            <div class="sub-item-select">
              <el-button type="success" :plain="item.ID != formData.PlanId" round @click="selectPackage(item)">{{ item.ID == formData.PlanId ? 'Selected' : 'Select' }} </el-button>
            </div>
          </div>
        </div>

        <el-form-item v-if="formData.PlanId != null" :label="'How many employee licenses would you like to purchase? Please include any existing licenses to indicate the total number of licenses needed.' + (sub != null && sub.Subscription != null && sub.Subscription.LifetimeFreeUsers != null && sub.Subscription.LifetimeFreeUsers > 0 ? ' (Exclude your current ' + sub.Subscription.LifetimeFreeUsers + ' lifetime user)' : '')" required>

          <!-- <p v-if="sub != null && sub.Subscription != null && (new Date(sub.Subscription.EndDate) > new Date())"></p>
           -->
          <el-input-number v-model="formData.UserCount" :min="5" :max="9999" required></el-input-number>
          <ValidationErrorItem validationKey="UserCount" />
        </el-form-item>



      <template v-if="formData.PlanId != null && formData.UserCount < employees.TotalCount">
      <div class="card p-7 mb-10 bg-orange" v-if="sub.Subscription">
      <div class="card-header">
        <div class="card-title">
          <h3>Attention</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">
        <li>Your current employee count is {{ employees.TotalCount }}, new employee count should be equal or bigger than current count.</li>
        <li>If you want to decrease your employee count you should remove your employees in your account by clicking <router-link :to="{name:'employees'}">here</router-link> before you proceed.</li>
      </ul>
    </div>
      </template>

      <template v-if="formData.PlanId != null && formData.UserCount >= employees.TotalCount">
          <div class="card p-5 bg-purple no-leader mt-10 mb-5" >
          <el-form-item label="Card holder" required>
            <el-input v-model="formData.CardHolder" placeholder="John Doe" required clearable></el-input>
            <ValidationErrorItem validationKey="CardHolder" />
          </el-form-item>

          <el-form-item label="Card number" required>
            <el-input v-model="formData.CardNumber" v-mask="'#### #### #### ####'" placeholder="0000 0000 0000 0000" required clearable></el-input>
            <ValidationErrorItem validationKey="CardNumber" />
          </el-form-item>

          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="Exp. month" required>
                <el-select v-model="formData.ExpireMonth" clearable placeholder="Select...">
                  <el-option v-for="item in 12" :key="item" :value="item.toString()">
                  <span style="float: left">{{ item }}</span>
                </el-option>
                </el-select>
                <ValidationErrorItem validationKey="ExpireMonth" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Exp. year" required>
                <el-select v-model="formData.ExpireYear" clearable placeholder="Select...">
                  <el-option v-for="item in years" :key="item" :value="item.toString()">
                  <span style="float: left">{{ item }}</span>
                </el-option>
                </el-select>
                <ValidationErrorItem validationKey="ExpireYear" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Cvv" required>
                <el-input v-model="formData.Cvc" placeholder="..." maxlength="4" required clearable></el-input>
                <ValidationErrorItem validationKey="Cvc" />
              </el-form-item>
            </el-col>
          </el-row>

          <div class="payment-type">
            <p>Pay annually for 2 months discount!</p>
            <el-switch v-model="formData.Yearly" active-text="Pay by year" inactive-text="Pay by month"> </el-switch>
          </div>

          <div class="summary">
            <div class="sum-icon">
              <i class="fas fa-shopping-basket"></i>
            </div>
            <div class="sum-desc">
              <p><b>Plan:</b> {{ plans.Items.find(x => x.ID == formData.PlanId).Title }}</p>
              <p><b>Employees:</b> {{ formData.UserCount }}</p>
              <p v-if="formData.Yearly"><b>Price:</b> <pre>${{ (plans.Items.find(x => x.ID == formData.PlanId).Price * formData.UserCount * 12).toFixed(2) }}</pre> ${{ (plans.Items.find(x => x.ID == formData.PlanId).Price * formData.UserCount * 10).toFixed(2) }} / annually</p>
              <p v-else><b>Price:</b> ${{ (plans.Items.find(x => x.ID == formData.PlanId).Price * formData.UserCount).toFixed(2) }} / monthly</p>
            </div>
          </div>

        </div>

        <div class="aggreements-area">
          <el-checkbox v-model="formData.Aggreements"> <span>I've ready and accept worktivity <a target="_blank" href="https://useworktivity.com/legal/terms-conditions">terms of use</a> and <a target="_blank" href="https://useworktivity.com/legal/distance-sales-policy">distance sales</a> policies.</span></el-checkbox>
          
        </div>

        <el-form-item class="tracking-buttons" v-if="formData.PlanId != null">
          <el-button :loading="loading == 'subscribe'" type="primary" icon="fas fa-check" round @click="subscribe">Pay now</el-button>
        </el-form-item>
        </template>
      
      </el-form>

      <div class="payment-providers mt-3">
        <img src="@/assets/img/logo_band_colored.svg" alt="Payment provider" />
      </div>

    </div>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" title="Subscription cancellation" :visible.sync="showCancellationModal" center modal-append-to-body destroy-on-close>
      <CancelSubscriptionModal @subscriptionCancelled="subscriptionCancelled" @closeDialog="showCancellationModal = false" v-if="showCancellationModal"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Redeem code" :visible.sync="redeemCodeModal" center modal-append-to-body destroy-on-close>
      <RedeemCodeModal @closeDialog="redeemCodeModal = false" v-if="redeemCodeModal"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Invoice information" :visible.sync="invoiceInformationModal" center modal-append-to-body destroy-on-close>
        <UpdateInvoiceSettingsModal @invoiceSaved="subscribe" @closeDialog="invoiceInformationModal = false" v-if="account" />
      </el-dialog>


    <div id="htmlContent" v-html="htmlContent">

    </div>

  </div>
</template>

<script>
import moment from "moment/min/moment-with-locales";
import CancelSubscriptionModal from './CancelSubscriptionModal.vue';
import RedeemCodeModal from './RedeemCodeModal.vue';

import UpdateInvoiceSettingsModal from '@/views/organization/UpdateInvoiceSettingsModal.vue';


import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'StartSubscriptionIndex',
    components: {
      Breadcrumb,
      CancelSubscriptionModal,
      RedeemCodeModal,
      UpdateInvoiceSettingsModal
    },

    data(){
      return {
        moment,
        startNewSubscription:false,
        meta: {
          title: 'Manage subscription',
          desc:'You can manage your subscription here.'
        },
        loading:null,
        formData: {
          PlanId: null,
          UserCount: 10,
          Yearly:false,
          CardHolder: '',
          CardNumber: '',
          ExpireMonth: '',
          ExpireYear: '',
          Cvc: '',
          Aggreements: false
        },
        showCancellationModal:false,
        redeemCodeModal:false,
        htmlContent:null,
        invoiceInformationModal:false,
      }
    },
    async mounted() {
      this.$setMeta(this.meta);

      await Promise.all([
        this.$store.dispatch("getPlans"),
        this.$store.dispatch("getSubscription"),
        this.$store.dispatch("getEmployees")
      ]);
      
      this.formData.UserCount = this.employees.TotalCount;
      if(this.employees.TotalCount < 5)
      {
        this.formData.UserCount = 5;
      }
      console.log(this.sub.Subscription);
      if(this.sub == null || this.sub.Subscription == null || (this.sub.Subscription.EndDate < new Date()) || this.sub.Subscription.Plan.Id == '641adf5362535c09fc3d8422')
      {
        this.startNewSubscription = true;
      }


      if(this.$route.query.action)
      {
        if(this.$route.query.status == "success")
        {
          try {
            var begin_purchase = JSON.parse(localStorage.getItem("begin_purchase"));
            this.$gtag.event('purchase', {
              transaction_id: this.$route.query.transactionId,
              currency: "USD",
              value: begin_purchase.Price,
              tax:0,
              shipping:0,
              items: [
                {
                  item_id: begin_purchase.Plan.ID,
                  item_name: begin_purchase.Plan.Title,
                  // discount: 0,
                  // index: 0,
                  item_brand: "Worktivity",
                  item_category: "Subscription",
                  price: begin_purchase.Price,
                  quantity: begin_purchase.UserCount
                }
              ]
            });
          } catch (error) {
            
          }

          this.$message.success("Thank you for your payment, your subscription started successfully.");
        }
        else
        {
          if(this.$route.query.message)
          {
            this.$message.info(this.$route.query.message);
          }
        }
      }
    },
    methods: {
      selectPackage(item) {
        this.formData.PlanId = item.ID;

        this.$gtag.event('add_to_cart', {
            currency: "USD",
            value: parseFloat(item.Price.toFixed(2)),
        });

        
      },
      async subscriptionCancelled() {
        await Promise.all([
          this.$store.dispatch("getPlans"),
          this.$store.dispatch("getSubscription"),
          this.$store.dispatch("getEmployees")
        ]);
      },
      openPricing() {
        window.open("https://useworktivity.com/pricing", "_blank");
      },
      openCancelModal() {
        this.showCancellationModal = true;
      },
      async subscribe() {
        if(!this.formData.Aggreements)
        {
          this.$message.info("Please confirm the aggreements before you proceed.");
          return;
        }

        if(this.organization.Invoice == null || this.organization.Invoice.CommercialTitleOrFullName == null)
        {
          //this.$message.info("Please fill your invoice information.");
          this.invoiceInformationModal = true;
          return;
        }

        this.loading = 'subscribe';
        var res = await this.$store.dispatch("startSubscription",this.formData);
        if(res != null)
        {
          try {
            this.$gtag.event('begin_purchase', {
              currency: "USD",
              value: parseFloat((this.plans.Items.find(x => x.ID == this.formData.PlanId).Price * this.formData.UserCount * (this.formData.Yearly ? 10 : 1)).toFixed(2)),
            });

            localStorage.setItem("begin_purchase", JSON.stringify({
              Plan: this.plans.Items.find(x => x.ID == this.formData.PlanId),
              PlanId: this.formData.PlanId,
              UserCount: this.formData.UserCount,
              Yearly:this.formData.Yearly,
              Price: parseFloat((this.plans.Items.find(x => x.ID == this.formData.PlanId).Price * this.formData.UserCount * (this.formData.Yearly ? 10 : 1)).toFixed(2))
            }));
          } catch (error) {
            
          }

          this.htmlContent = res.HtmlContent;

          this.$nextTick(function () {
            //document.getElementById("iyzico-3ds-form").submit();
            setTimeout(() => {
              var forms = document.getElementById("htmlContent").getElementsByTagName("form");
              if(forms.length == 1)
              {
                document.getElementById("htmlContent").getElementsByTagName("form")[0].submit()
              }
              else
              {
                document.getElementById("htmlContent").getElementsByTagName("form")[forms.length - 1].submit()
              }
              
            },500);
          });
        }
        this.loading = null;
      }
    },
    computed: {
      years() {
        var result = [];
        for (let i = 2024; i <= 2100; i++) {
          result.push(i);
        }

        return result;
      },
      sub() {
          return this.$store.getters.getSubscription;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      plans() {
          return this.$store.getters.getPlans;
      },
      employees() {
        var a = this.$store.getters.getEmployees;
      if(this.sub != null && this.sub.Subscription != null && this.sub.Subscription.LifetimeFreeUsers)
      {
        a.TotalCount -= this.sub.Subscription.LifetimeFreeUsers;
      }
          return a;
      },
    },
}
</script>
