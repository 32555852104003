<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter" v-dragscroll.x>
      <div class="filter-item purple" :class="showPage == 0 ? 'isactive' : ''" @click="showPage = 0">
        <i class="fas fa-building"></i>
        <span>Profile</span>
      </div>
      <div class="filter-item purple" :class="showPage == 1 ? 'isactive' : ''" @click="showPage = 1">
        <i class="fas fa-user-secret"></i>
        <span>Track settings</span>
      </div>
      <div class="filter-item purple" :class="showPage == 2 ? 'isactive' : ''" @click="showPage = 2">
        <i class="fas fa-exclamation-triangle"></i>
        <span>Risk user settings</span>
      </div>
      <div class="filter-item purple" :class="showPage == 3 ? 'isactive' : ''" @click="showPage = 3">
        <i class="fas fa-project-diagram"></i>
        <span>API access</span>
      </div>
    </div>

    <template v-if="showPage == 0">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Organization profile</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">

          <el-form :label-position="'top'" label-width="100px">

            <el-form-item label="Organization logo" required>
              <el-upload class="avatar-uploader"
                :action="axios.defaults.baseURL + 'account/UploadFile?UploadPath=Organization'"
                :headers="{ Authorization: currentToken() }" :show-file-list="false" :on-success="uploadSuccess"
                :before-upload="beforeUpload" :onProgress="fileUploadProgress">
                <img v-if="organization.Image" :src="organization.Image" class="avatar">
                <i class="el-icon-plus avatar-uploader-icon" v-if="!uploadInProgress"></i>
                <i class="fa fa-spinner fa-spin avatar-uploader-icon" v-else></i>

              </el-upload>
              <ValidationErrorItem validationKey="imageUrl" />
            </el-form-item>

            <el-form-item label="Organization name" required>
              <el-input v-model="organization.Title" placeholder="Acme Inc." required clearable></el-input>
              <ValidationErrorItem validationKey="Title" />
            </el-form-item>

            <el-form-item label="Organization no">
              <el-input v-model="organization.OrganizationNo" placeholder="..." disabled></el-input>
              <ValidationErrorItem validationKey="OrganizationNo" />
            </el-form-item>

            <el-form-item label="What is your organization's industry?" required>
              <IndustrySelectbox :selectedId="organization.Industry" @changed="(id) => organization.Industry = id" />
              <ValidationErrorItem validationKey="Industry" />
            </el-form-item>

            <el-form-item label="What is your main expectation from using Worktivity?" required>
              <OrganizationExpectationSelectbox :selectedId="organization.Expectation"
                @changed="(id) => organization.Expectation = id" />
              <ValidationErrorItem validationKey="Expectation" />
            </el-form-item>

            <el-form-item label="Organization type" required>
              <el-radio-group v-model="organization.Invoice.Type">
                <el-radio :label="0">Individual</el-radio>
                <el-radio :label="1">Corporate</el-radio>
              </el-radio-group>
              <ValidationErrorItem validationKey="Invoice.Type" />
            </el-form-item>

            <el-form-item :label="organization.Invoice.Type == 0 ? 'Full name' : 'Commercial title'" required>
              <el-input v-model="organization.Invoice.CommercialTitleOrFullName" placeholder="Acme Inc." required
                clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.CommercialTitleOrFullName" />
            </el-form-item>

            <el-form-item :label="organization.Invoice.Type == 0 ? 'Identity number' : 'Tax number'" required>
              <el-input v-model="organization.Invoice.TaxNumberOrIdentity" placeholder="..." required
                clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.TaxNumberOrIdentity" />
            </el-form-item>

            <el-form-item label="Tax office" v-if="organization.Invoice.Type == 1">
              <el-input v-model="organization.Invoice.TaxOffice" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.TaxOffice" />
            </el-form-item>

            <el-form-item label="Country" required>
              <el-input v-model="organization.Invoice.Country" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.Country" />
            </el-form-item>

            <el-form-item label="Zip code">
              <el-input v-model="organization.Invoice.ZipCode" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.ZipCode" />
            </el-form-item>

            <el-form-item label="Invoice address" required>
              <el-input v-model="organization.Invoice.Address" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="Invoice.Address" />
            </el-form-item>


            <el-form-item class="space-between-buttons">
              <el-button type="danger" icon="fas fa-trash" round @click="deleteOrganizationModal = true">Delete
                organization</el-button>

              <el-button :loading="loading == 'updateProfile'" type="primary" icon="fas fa-check" round
                @click="saveChanges">Save changes</el-button>
            </el-form-item>
          </el-form>

        </div>

      </div>
    </template>

    <template v-if="showPage == 1">
      <el-form :label-position="'top'" label-width="100px" :model="trackSettings">
        <div class="card p-5 mb-10">

          <div class="card-header hr">
            <div class="card-title">
              <h3>Work day/time settings</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">


            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="Working days" required>
                  <el-checkbox-group v-model="trackSettings.WorkingDays">
                    <el-checkbox :label="1" name="type">Monday</el-checkbox>
                    <el-checkbox :label="2" name="type">Tuesday</el-checkbox>
                    <el-checkbox :label="3" name="type">Wednesday</el-checkbox>
                    <el-checkbox :label="4" name="type">Thursday</el-checkbox>
                    <el-checkbox :label="5" name="type">Friday</el-checkbox>
                    <el-checkbox :label="6" name="type">Saturday</el-checkbox>
                    <el-checkbox :label="7" name="type">Sunday</el-checkbox>
                  </el-checkbox-group>
                  <ValidationErrorItem validationKey="days" />
                </el-form-item>
              </el-col>
            </el-row>



            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Expected work hours" required>
                  <el-select v-model="trackSettings.ExpectedWorkHours" placeholder="Select...">
                    <el-option v-for="item in workingHours" :key="item.Key" :label="item.Value" :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="ExpectedWorkHours" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Minimum productive work hours" required>
                  <el-select v-model="trackSettings.MinimumProductiveMins" placeholder="Select...">
                    <el-option v-for="item in workingHours" :key="item.Key" :label="item.Value" :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="minProductive" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Expected clock-in" required>
                  <el-time-select v-model="trackSettings.Clockin" :picker-options="timePickerOptions"
                    placeholder="Select..."></el-time-select>
                  <ValidationErrorItem validationKey="Clockin" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Expected clock-out" required>
                  <el-time-select v-model="trackSettings.Clockout" :picker-options="timePickerOptions"
                    placeholder="Select..."></el-time-select>
                  <ValidationErrorItem validationKey="Clockout" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Idle time settings" required>
                  <el-select v-model="trackSettings.IdleTimeMins" placeholder="Select...">
                    <el-option v-for="item in idleTimes" :key="item.Key" :label="item.Value" :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="IdleTimeMins" />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Organization timezone" required>
                  <el-select v-model="trackSettings.Timezone" filterable placeholder="Select...">
                    <el-option v-for="item in timezones" :key="item.Id"
                      :label="item.Title + ' (' + item.BaseUtcOffset + ')'" :value="item.Id">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="timeZone" />
                </el-form-item>
              </el-col>
            </el-row>

          </div>

          <div class="card-header hr mt-10">
            <div class="card-title">
              <h3>Screenshot settings</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">

            <el-row :gutter="24" class="mb-3 mt-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.EnableScreenshots" active-text="Enable screen capture" />
                <ValidationErrorItem validationKey="EnableScreenshots" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.BlurScreenshots" active-text="Blur captured screenshots"
                  :disabled="sub.Subscription.Plan.ScreenshotBlur != 0" />
                <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.ScreenshotBlur != 0"
                  @click="goToBilling">UPGRADE NOW</span>
                <ValidationErrorItem validationKey="BlurScreenshots" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.EnableTimelapseVideos"
                  :disabled="sub.Subscription.Plan.TimelapseVideos != 0" active-text="Enable timelapse videos" />
                <ValidationErrorItem validationKey="EnableTimelapseVideos" />
                <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.TimelapseVideos != 0"
                  @click="goToBilling">UPGRADE NOW</span>
              </el-col>
            </el-row>
            <el-row :gutter="24" v-if="trackSettings.EnableScreenshots && sub">
              <el-col :span="24">
                <el-form-item label="Screen capture interval" required>
                  <el-select v-model="trackSettings.ScreenCaptureIntervalMins" placeholder="Select...">

                    <el-option v-for="item in idleTimes" :key="item.Key" :label="item.Value"
                      :disabled="sub.Subscription.Plan.ScreenshotMinInterval > item.Key" :value="item.Key">
                      <div>
                        <el-tooltip class="item" effect="dark" content="Please upgrade your subscription"
                          placement="top" v-if="sub.Subscription.Plan.ScreenshotMinInterval > item.Key">
                          <span>
                            {{ item.Value }}
                          </span>
                        </el-tooltip>
                        <span v-else>
                          {{ item.Value }}
                        </span>
                      </div>
                    </el-option>

                  </el-select>
                  <ValidationErrorItem validationKey="ScreenCaptureIntervalMins" />
                </el-form-item>
              </el-col>
            </el-row>

          </div>

          <div class="card-header hr mt-10">
            <div class="card-title">
              <h3>Custom app & website rules</h3>
            </div>

          </div>
          <div class="track-settings-wrapper">

            <el-row :gutter="24" class="mb-3 ">
              <el-col :span="24">
                <p>Always working</p>
                <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>New records for these applications
                    and websites will always be calculated as <b>Working</b>. For example, you can use this field to
                    ensure that long-duration training meetings that do not require any keyboard and mouse activity are
                    not counted as idle. It is case-sensitive.</span></p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 mt-3">
              <el-col :span="24">

                <el-select v-model="trackSettings.AlwaysWorkingApps" multiple allow-create filterable
                  default-first-option
                  placeholder="ex: Type meet.google.com, us04web.zoom.us or Google Meet and press Enter">
                  <el-option v-for="item in trackSettings.AlwaysWorkingApps" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="AlwaysWorkingApps" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 ">
              <el-col :span="24">
                <p>Always on break</p>
                <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>New records for these applications
                    and websites will always be calculated as <b>On Break</b>. For example, you can use this to
                    automatically count applications like YouTube, Spotify, and others as <b>On Break</b>. It is
                    case-sensitive.</span></p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 mt-3">
              <el-col :span="24">

                <el-select v-model="trackSettings.AlwaysOnBreakApps" multiple allow-create filterable
                  default-first-option
                  placeholder="ex: Type youtube.com, spotify.com or music.youtube.com and press Enter">
                  <el-option v-for="item in trackSettings.AlwaysOnBreakApps" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="AlwaysOnBreakApps" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 ">
              <el-col :span="24">
                <p style="font-style:italic; font-size:11px; margin-top:5px;">The changes you make in this section may
                  take up to 15 minutes to synchronize.</p>
                <p></p>
              </el-col>
            </el-row>


          </div>





          <div class="card-header hr mt-10">
            <div class="card-title">
              <h3>Additional features</h3>
            </div>
          </div>


          <div class="track-settings-wrapper">
            <template v-if="sub">

              <el-row :gutter="24" class="mb-3 ">
                <el-col :span="12">
                  <p>Task tracking</p>
                  <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>Desktop version 2.0.1 or above
                      versions.</span></p>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.EnableProjectManagement" active-text="Enable task tracking"
                    :disabled="sub.Subscription.Plan.TaskTracking != 0" />
                  <ValidationErrorItem validationKey="EnableProjectManagement" />
                  <!-- <span class="badge badge-sm green ml-2" v-if="sub.Subscription.Plan.TaskTracking == 0">NEW</span> -->
                  <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling"
                    v-if="sub.Subscription.Plan.TaskTracking != 0">UPGRADE NOW</span>
                </el-col>
              </el-row>

              <template>
                <el-row :gutter="24" class="mb-3 mt-10">
                  <el-col :span="12">
                    <p>Cost management</p>
                    <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>After enabling cost management,
                        please visit the Employees page and edit the hourly rates of your employees.</span></p>
                  </el-col>
                </el-row>

                <el-row :gutter="24" class="mb-3">
                  <el-col :span="12">
                    <el-switch v-model="trackSettings.EnableCostManagement"
                      active-text="Enable cost management & payroll"
                      :disabled="sub.Subscription.Plan.PayrollCalculator != 0" />
                    <ValidationErrorItem validationKey="EnableCostManagement" />
                    <span class="badge badge-sm blue cursor-pointer ml-2" @click="goToBilling"
                      v-if="sub.Subscription.Plan.PayrollCalculator != 0">UPGRADE NOW</span>
                  </el-col>
                </el-row>

                <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableCostManagement">
                  <el-col :span="12">
                    <CurrencySelectbox :selectedId="trackSettings.CostManagementCurrency"
                      @changed="(id) => trackSettings.CostManagementCurrency = id" />
                    <ValidationErrorItem validationKey="CostManagementCurrency" />
                  </el-col>
                </el-row>

              </template>

              <el-row :gutter="24" class="mb-3 mt-10">
                <el-col :span="12">
                  <p>Leave management</p>
                  <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>Manage daily and annual leave for
                      your
                      employees. Don't forget to set up leave policies for your employees from "Leave rights" screen
                      after enabling
                      this feature.</span></p>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.EnableLeaveManagement" active-text="Enable leave management"
                    :disabled="sub.Subscription.Plan.LeaveManagement != 0" />
                  <span class="badge badge-sm yellow ml-2">BETA</span>

                  <ValidationErrorItem validationKey="EnableLeaveManagement" />
                  <span class="badge badge-sm green ml-2" v-if="sub.Subscription.Plan.LeaveManagement == 0">NEW</span>
                  <span class="badge badge-sm blue cursor-pointer  ml-2" v-else @click="goToBilling">UPGRADE NOW</span>
                </el-col>
              </el-row>

              <el-row :gutter="24" class="mb-3 mt-10">
                <el-col :span="12">
                  <p>Background mode (Windows only)</p>
                  <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>You can use this feature to
                      monitor the
                      computers of your employees in the background.</span></p>
                  <p style="font-style:italic; font-size:11px;"><span>In devices belonging to the MacOS operating system, it only functions as an auto-start feature, and the application continues to appear in the MacOS taskbar and Dock.</span></p>
                  <p style="font-style:italic; font-size:11px;"><span>You need to add Worktivity to the startup applications on your computer.</span></p>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="mb-3">
                <el-col :span="24">
                  <el-tooltip :disabled="!trackSettings.EnableProjectManagement"
                    content='You cannot use this feature in conjunction with the "Task Tracking" feature. When you enable this feature, the desktop application runs in the background, making it impossible to select a Task.'
                    placement="top">
                    <el-switch v-model="trackSettings.EnableGhostMode" active-text="Enable background mode"
                      :disabled="sub.Subscription.Plan.GhostMode != 0 || trackSettings.EnableProjectManagement" />
                  </el-tooltip>
                  <ValidationErrorItem validationKey="EnableGhostMode" />
                  <span class="badge badge-sm green ml-2" v-if="sub.Subscription.Plan.GhostMode == 0">NEW</span>
                  <span class="badge badge-sm blue cursor-pointer  ml-2" v-else @click="goToBilling">UPGRADE NOW</span>
                </el-col>
                <el-col :span="12" v-if="trackSettings.EnableGhostMode">
                  <div class="card bg-blue p-5 mt-3">
                    <div class="card-alert">
                      <i class="fas fa-info-circle"></i>
                      <div>
                        <span>The use of this feature may vary according to the legal conditions in your country.</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="mb-3 mt-10">
                <el-col :span="12">
                  <p>Auto clock-in</p>
                  <p style="font-style:italic; font-size:11px; margin-top:5px;"><span>The Worktivity application automatically clocks in the user as soon as it is opened.</span></p>
                  <p style="font-style:italic; font-size:11px;"><span>You need to add Worktivity to the startup applications on your computer.</span></p>
                </el-col>
              </el-row>
              <el-row :gutter="24" class="mb-3">
                <el-col :span="24">
                  <el-switch v-model="trackSettings.EnableAutoClockIn" active-text="Enable auto clock-in"
                      :disabled="sub.Subscription.Plan.AutoClockIn != 0" />
                  <ValidationErrorItem validationKey="EnableAutoClockIn" />
                  <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling" v-if="sub.Subscription.Plan.AutoClockIn != 0">UPGRADE NOW</span>
                  <span class="badge badge-sm green ml-2">NEW</span>
                </el-col>
                <el-col :span="12" v-if="trackSettings.EnableAutoClockIn">
                  <div class="card bg-blue p-5 mt-3">
                    <div class="card-alert">
                      <i class="fas fa-info-circle"></i>
                      <div>
                        <span>The use of this feature may vary according to the legal conditions in your country.</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>


            </template>
          </div>

          <div class="card-header mt-10 hr">
            <div class="card-title">
              <h3>Permissions</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">


            <el-row :gutter="24" class="mb-3 mt-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <p>Screenshots</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToDeleteTheirMembersScreenCapture"
                  active-text="Allow managers to delete their team member's screenshot." />
                <ValidationErrorItem validationKey="AllowManagersToDeleteTheirMembersScreenCapture" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirScreenCapture"
                  active-text="Allow the employee to see their screen capture." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirScreenCapture" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToDeleteTheirScreenCapture"
                  active-text="Allow the employee to delete their screen capture." />
                <ValidationErrorItem validationKey="AllowEmployeeToDeleteTheirScreenCapture" />
              </el-col>
            </el-row>


            <el-row :gutter="24" class="mb-3 mt-10" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <p>Timelapse video</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToDeleteTheirMembersTimelapseVideo"
                  active-text="Allow managers to delete their team member's timelapse video." />
                <ValidationErrorItem validationKey="AllowManagersToDeleteTheirMembersTimelapseVideo" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirTimelapseVideo"
                  active-text="Allow the employee to see their time-lapse video." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirTimelapseVideo" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 mt-10">
              <el-col :span="12">
                <p>Timesheet</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirTimesheet"
                  active-text="Allow the employee to see their timesheet." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirTimesheet" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 mt-10">
              <el-col :span="12">
                <p>Manual time entries</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeesToAddManualTimeEntries"
                  active-text="Allow the employees and managers to add manual time entries" />
                <ValidationErrorItem validationKey="AllowEmployeesToAddManualTimeEntries" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3">
              <el-col :span="12">
                <el-switch :disabled="!trackSettings.AllowEmployeesToAddManualTimeEntries"
                  v-model="trackSettings.AutoApproveForTimeEntries"
                  active-text="Enable automatic approval for manual time entries." />
                <ValidationErrorItem validationKey="AutoApproveForTimeEntries" />
              </el-col>
            </el-row>


            <template v-if="trackSettings.EnableProjectManagement">
              <el-row :gutter="24" class="mb-3 mt-10">
                <el-col :span="12">
                  <p>Task tracking</p>
                </el-col>
              </el-row>

              <!-- <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowManagersToCreateAndEditCustomers"
                    active-text="Allow the managers to create and edit customers." />
                  <ValidationErrorItem validationKey="AllowManagersToCreateAndEditCustomers" />
                </el-col>
              </el-row>

              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowManagersToDeleteCustomers"
                    :disabled="!trackSettings.AllowManagersToCreateAndEditCustomers"
                    active-text="Allow the managers to delete customers." />
                  <ValidationErrorItem validationKey="AllowManagersToDeleteCustomers" />
                </el-col>
              </el-row>

              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowManagersToCreateAndEditProjects"
                    active-text="Allow the managers to create and edit projects." />
                  <ValidationErrorItem validationKey="AllowManagersToCreateAndEditProjects" />
                </el-col>
              </el-row>

              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowManagersToDeleteProjects"
                    :disabled="!trackSettings.AllowManagersToCreateAndEditProjects"
                    active-text="Allow the managers to delete projects." />
                  <ValidationErrorItem validationKey="AllowManagersToCreateAndEditProjects" />
                </el-col>
              </el-row> -->

              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowEmployeesToCreateAndEditTasks"
                    active-text="Allow the employees and managers to create and edit tasks." />
                  <ValidationErrorItem validationKey="AllowEmployeesToCreateAndEditTasks" />
                </el-col>
              </el-row>

              <el-row :gutter="24" class="mb-3">
                <el-col :span="12">
                  <el-switch v-model="trackSettings.AllowEmployeesToDeleteTasks"
                    active-text="Allow the employees and managers to delete tasks." />
                  <ValidationErrorItem validationKey="AllowEmployeesToDeleteTasks" />
                </el-col>
              </el-row>
            </template>

            <el-row :gutter="24" class="mb-3 mt-10">
              <el-col :span="12">
                <p>Review apps</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToSeeAppReviews"
                  active-text="Allow managers to see app reviews." />
                <ValidationErrorItem validationKey="AllowManagersToSeeAppReviews" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.AllowManagersToSeeAppReviews">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToChangeAppReviews"
                  active-text="Allow the managers to change app reviews." />
                <ValidationErrorItem validationKey="AllowManagersToChangeAppReviews" />
              </el-col>
            </el-row>


          </div>

          <el-form-item class="tracking-buttons">
            <el-button :loading="loading == 'updateTrackSettings'" type="primary" icon="fas fa-check" round
              @click="updateTrackSettings">Save changes</el-button>
          </el-form-item>
        </div>
      </el-form>
    </template>

    <template v-if="showPage == 2">
      <!-- <ComingSoon feature="Risk user settings" /> -->


      <div class="card p-5" v-if="!riskUserSettings">

        <div class="card-header hr">
          <div class="card-title">
            <h3>Risk user settings</h3>
          </div>
        </div>

        <el-skeleton :rows="12" animated />
      </div>

      <div class="card p-5" v-if="riskUserSettings">

        <div class="card-header hr">
          <div class="card-title d-flex justify-content-between w-100">
            <h3>Risk user settings</h3>
            <div>
              <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
                <el-button type="link" round icon="fas fa-sync" @click="getRiskUserSettings"
                  :loading="loading == 'riskUserSettings'"></el-button>
              </el-tooltip>
              <el-button round icon="fas fa-plus"
                @click="riskUserData = null; addRiskUserSettingsModal = true">Add</el-button>
            </div>
          </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="medium" title="Add risk user settings"
          :visible.sync="addRiskUserSettingsModal" center modal-append-to-body destroy-on-close>
          <RiskUserSettingsAddOrUpdateModal :data="riskUserData" @riskUserSettingsUpdated="getRiskUserSettings"
            @closeDialog="addRiskUserSettingsModal = false" v-if="addRiskUserSettingsModal" />
        </el-dialog>

        <NoData v-if="riskUserSettings.Items.length == 0" />
        <el-table class="w-100" v-if="riskUserSettings.Items.length > 0" :data="riskUserSettings.Items"
          style="width: 100%" ref="tab" v-loading="loading == 'riskUserSettings'">

          <el-table-column label="Title">
            <template slot-scope="scope">
              <p>{{ scope.row.Title }}</p>
            </template>
          </el-table-column>

          <el-table-column label="Condition(s)" class-name="text-center" width="100">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="$getRiskUserTooltipText(scope.row)" placement="top">
                <span class="badge cursor-pointer">{{ scope.row.Conditions.length }}</span>
              </el-tooltip>
            </template>
          </el-table-column>


          <el-table-column label="Created at" class-name="text-center" width="180">
            <template slot-scope="scope">
              <span class="badge cursor-pointer">{{ moment(scope.row.CreateDate).format('DD MMM yyyy - HH:mm') }}</span>
            </template>
          </el-table-column>

          <el-table-column width="100">
            <template slot-scope="scope">
              <el-row :key="scope.row.ID" class="d-flex justify-content-between">

                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                  <span>
                    <el-button type="info" icon="fas fa-pen" size="small" circle
                      @click.stop="updateRiskUserSettings(scope.row)"></el-button>
                  </span>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                  <span>
                    <el-button type="danger" icon="fas fa-trash" size="small" circle
                      @click.stop="deleteRiskUserSettings(scope.row)"
                      :loading="loading == 'delete-' + scope.row.ID"></el-button>
                  </span>
                </el-tooltip>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </template>

    <template v-if="showPage == 3">
      <ComingSoon feature="API access" />
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" title="Delete organization"
      :visible.sync="deleteOrganizationModal" center modal-append-to-body destroy-on-close>
      <DeleteOrganizationModal @closeDialog="deleteOrganizationModal = false" v-if="deleteOrganizationModal" />
    </el-dialog>


  </div>
</template>

<script>

import axios from "axios"

import OrganizationExpectationSelectbox from '@/components/form/OrganizationExpectationSelectbox.vue';
import IndustrySelectbox from '@/components/form/IndustrySelectbox.vue';
import CurrencySelectbox from '@/components/form/CurrencySelectbox.vue';

import DeleteOrganizationModal from './DeleteOrganizationModal.vue';
import Breadcrumb from '../partials/Breadcrumb';
import { dragscroll } from "vue-dragscroll";
import moment from "moment/min/moment-with-locales";

import RiskUserSettingsAddOrUpdateModal from './RiskUserSettingsAddOrUpdateModal.vue';


export default {
  name: 'OrganizationSettingsIndex',
  directives: {
    dragscroll
  },
  components: {
    Breadcrumb,
    DeleteOrganizationModal,
    OrganizationExpectationSelectbox,
    IndustrySelectbox,
    CurrencySelectbox,
    RiskUserSettingsAddOrUpdateModal,

  },
  data() {
    return {
      moment,
      uploadInProgress: false,
      axios,
      alwayWorkingApppInputVisible: false,
      alwayWorkingApppInputValue: '',
      meta: {
        title: 'Organization settings',
        desc: 'You can change organization profile or track settings here.'
      },
      deleteOrganizationModal: false,
      timePickerOptions: {
        start: '00:15',
        step: '00:15',
        end: '23:45'
      },
      showPage: 0,
      loading: null,
      addRiskUserSettingsModal: false,
      riskUserData: null
    }
  },
  async mounted() {
    if (this.$route.query.activeTab) {
      if (this.$route.query.activeTab == "track-settings") {
        this.showPage = 1;
      }
    }
    this.$setMeta(this.meta);
    await this.$store.dispatch("getTrackSettings");
    await this.$store.dispatch("getTimezones");
    await this.$store.dispatch("getSubscription");

    if (this.$route.query.reportId) {
      this.showPage = 2;
      await this.getRiskUserSettings();
      var item = this.riskUserSettings.Items.find(x => x.ID == this.$route.query.reportId);
      if (item != null) {
        this.updateRiskUserSettings(item);
      }
      else {
        this.$router.push({ name: 'settings' }).catch(() => { });
      }
    }

  },
  methods: {
    handleClose(tag) {
      this.trackSettings.AlwaysWorkingApps.splice(this.AlwaysWorkingApps.indexOf(tag), 1);
    },

    showInput() {
      this.alwayWorkingApppInputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.alwayWorkingApppInputValue;
      if (inputValue) {
        this.trackSettings.AlwaysWorkingApps.push(inputValue);
      }
      this.alwayWorkingApppInputVisible = false;
      this.alwayWorkingApppInputValue = '';
    },
    currentToken() {
      return localStorage.getItem('token');
    },
    goToBilling() {
      this.$router.push({ name: 'billing' })
    },
    async saveChanges() {
      this.loading = 'updateProfile';
      await this.$store.dispatch("updateOrganizationProfile", this.organization);
      this.loading = null;
    },

    async updateTrackSettings() {
      this.loading = 'updateTrackSettings';
      var res = await this.$store.dispatch("updateTrackSettings", this.trackSettings);
      if (res != null) {
        await this.$store.commit("setCurrentOrganizationTracksettings", JSON.parse(JSON.stringify(this.trackSettings)));
      }
      this.loading = null;
    },
    fileUploadProgress(event) {
      this.uploadInProgress = true;
    },
    uploadSuccess(res, file) {
      this.uploadInProgress = false;
      if (res.HasError) {
        this.$message.error(res.Message);
      } else {
        this.$message.success(res.Message);
        this.organization.Image = res.Data.Url;
        this.saveChanges();
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('Avatar picture must be JPG or PNG format.');
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!');
      }
      return isJPG && isLt2M;
    },

    //RISK USER SETTINGS
    async getRiskUserSettings() {
      this.loading = 'riskUserSettings';
      var res = await this.$store.dispatch("riskUserSettings", {
        Page: 1,
        PageSize: 1000,
      });
      this.loading = null;
    },
    updateRiskUserSettings(item) {
      this.riskUserData = item;
      this.addRiskUserSettingsModal = true;
    },
    async deleteRiskUserSettings(item) {

      this.$confirm('You are about to delete this setting. This action can not be undo, are you sure about this?', 'Confirmation', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        this.loading = "delete-" + item.ID;
        var res = await this.$store.dispatch("deleteRiskUserSettings", {
          Id: item.ID
        });
        if (res != null) {
          this.getRiskUserSettings();
        }
        this.loading = null;
      }).catch(() => {
        this.loading = null;
      });


    }
  },
  computed: {
    riskUserSettings() {
      return this.$store.getters.getRiskUserSettings;
    },
    timezones() {
      return this.$store.getters.getTimezones;
    },
    account() {
      return this.$store.getters.getAccount;
    },
    organization() {
      return this.account.CurrentOrganization;
    },
    trackSettings() {
      return this.$store.getters.getTrackSettings;
    },
    workingHours() {
      var result = [];

      for (let i = 15; i <= 960; i += 15) {
        result.push({
          Key: i,
          Value: this.$parseTime(i)
        });
      }

      return result;
    },
    idleTimes() {
      var result = [];

      for (let i = 1; i <= 60; i++) {
        result.push({
          Key: i,
          Value: "Every " + i + " minutes"
        });
      }

      return result;
    },
    sub() {
      return this.$store.getters.getSubscription;
    },
  },
  watch: {
    'showPage'() {
      if (this.showPage == 2) //Risk user settings
      {
        this.getRiskUserSettings();
        //console.log('risk user settings');  
      }

    },
    'trackSettings.EnableScreenshots'() {
      if (this.trackSettings.EnableScreenshots == false) {
        this.trackSettings.BlurScreenshots = false;
        this.trackSettings.EnableTimelapseVideos = false;
      }
    },
    'trackSettings.AllowEmployeesToAddManualTimeEntries'() {
      if (this.trackSettings.AllowEmployeesToAddManualTimeEntries == false) {
        this.trackSettings.AutoApproveForTimeEntries = false;
      }
    },
    'trackSettings.AllowManagersToCreateAndEditProjects'() {
      if (this.trackSettings.AllowManagersToCreateAndEditProjects == false) {
        this.trackSettings.AllowManagersToDeleteProjects = false;
      }
    },
    'trackSettings.AllowManagersToCreateAndEditCustomers'() {
      if (this.trackSettings.AllowManagersToCreateAndEditCustomers == false) {
        this.trackSettings.AllowManagersToDeleteCustomers = false;
      }
    },
    'trackSettings.AllowManagersToSeeAppReviews'() {
      if (this.trackSettings.AllowManagersToSeeAppReviews == false) {
        this.trackSettings.AllowManagersToChangeAppReviews = false;
      }
    },
  }
}
</script>


<style>
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
