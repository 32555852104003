<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => { filter.TeamId = id; getData() }" :key="filter.TeamId" />
        </div>
        <div class="filter-item">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id" :key="filter.ProjectId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement && filter.ProjectId != null">
          <TaskSelectbox :projectId="filter.ProjectId" @changed="(id) => filter.ProjectTaskId = id" :key="filter.ProjectId" />
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5 mb-10" v-if="appsSummary" v-loading="loading == 'getData'">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Total calculated work time:</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge no-cursor" :class="productivityBadgeBg(parseInt($percentage($sum(appsSummary.Items, 'Productive'), $sum(appsSummary.Items, 'Working'))))">{{$parseTime($sum(appsSummary.Items, "Working")) }}</span>
        </div>
      </div>

      <div class="percentage-container">
        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard" :percentage="parseInt($percentage($sum(appsSummary.Items, 'Productive'), $sum(appsSummary.Items, 'Working')))" :stroke-width="12" :color="'#45C4A0'"></el-progress>
          </div>
          <div class="percentage-overview green">
            <i class="fas fa-check-circle"></i>
            <div class="percentage-info">
              <h6>Productive</h6>
              <span>Total {{ $parseTime($sum(appsSummary.Items, "Productive")) }}</span>
            </div>
          </div>
        </div>

        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard" :percentage="parseInt($percentage($sum(appsSummary.Items, 'Natural'), $sum(appsSummary.Items, 'Working')))" :stroke-width="12" :color="'#54A8C7'"></el-progress>
          </div>
          <div class="percentage-overview blue">
            <i class="fas fa-minus-circle"></i>
            <div class="percentage-info">
              <h6>Neutral</h6>
              <span>Total {{ $parseTime($sum(appsSummary.Items, "Natural")) }}</span>
            </div>
          </div>
        </div>

        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard" :percentage="parseInt($percentage($sum(appsSummary.Items, 'Unproductive'), $sum(appsSummary.Items, 'Working')))" :stroke-width="12" :color="'#E2626B'"></el-progress>
          </div>
          <div class="percentage-overview red">
            <i class="fas fa-times-circle"></i>
            <div class="percentage-info">
              <h6>Unproductive</h6>
              <span>Total {{ $parseTime($sum(appsSummary.Items, "Unproductive")) }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card p-5" v-if="!appsSummary">
      <el-skeleton :rows="12" animated />
    </div>
     <template v-if="appsSummary">
     <template v-for="item in currentTeams">
      <div class="card p-5 mb-10" :key="item.ID" v-loading="loading == 'getData'">
        <div class="card-header" :class="activeList.find(x => x == item.ID) ? '' : 'hr'">
        <div class="card-title">
          <h3><i class="fas fa-circle" :style="'color: ' + item.Color"></i> {{ item.Title }}</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge blue" @click="toggleActiveList(item.ID)">{{item.EmployeeCount}} Employee <i class="fas" :class="activeList.find(x => x == item.ID) ? 'fa-angle-up' : 'fa-angle-down'"></i></span>
        </div>
      </div>

      <div class="activity-table" :class="activeList.find(x => x == item.ID) ? '' : 'hidden-list'">
        <el-table :data="appsSummary.Items.filter(x => x.OrganizationApp.TeamId == item.ID)">

          <el-table-column label="Application" min-width="250">
            <template slot-scope="scope">
              <div class="app-title">
                <el-avatar :size="25" shape="square" :src="scope.row.ActivityApp.Icon"></el-avatar>
                <div>
                  <span>{{ scope.row.ActivityApp.Name }}
                  <a target="_blank" :href="'http://' + scope.row.ActivityApp.Url" v-if="scope.row.ActivityApp.Url">{{ scope.row.ActivityApp.Url }}</a>
                  </span>
                  <el-tooltip class="ai-item" effect="dark" :content="'The Worktivity AI model automatically identified this application as suitable for the '+ scope.row.ActivityApp.AICategory +' category.'" placement="bottom" v-if="scope.row.ActivityApp.AICategory != null">
                    <p><i class="fa fa-info-circle"></i> {{ scope.row.ActivityApp.AICategory }}</p>
                  </el-tooltip>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="" width="150">
            <template slot-scope="scope">
              <el-progress :color="$store.state.darkMode ? '#747ed1' : ''" :stroke-width="10" :percentage="parseInt($percentage(scope.row.Working, $sum(appsSummary.Items, 'Working')))" style="width:100%;"></el-progress>
            </template>
          </el-table-column>

          <el-table-column label="Total working hours" width="150">
            <template slot-scope="scope">
              <span class="badge" :style="scope.row.Working == 0 ? 'opacity:.3' : ''">{{ $parseTime(scope.row.Working) }}</span>
            </template>
          </el-table-column>

          

          <el-table-column label="Productivity" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :class="$getProductivityBadgeBg(scope.row.Productivity)">
                {{ $getEnumTitle("ProductivityStatus", scope.row.Productivity) }}
              </span>
            </template>
          </el-table-column>

        </el-table>
      </div>

    </div>
      </template>
      
         <div class="d-flex mb-5 justify-content-center" v-if="appsSummary && appsSummary.Items.length > 0">
          <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
            Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
          </el-button>
          <div class="d-flex align-items-center">
            <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
          </div>
          </div>
          
    </template>
  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';

export default {
    name: 'AppsSummaryIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      ProjectSelectbox,
      TaskSelectbox,
      DatePicker,
    },

    data(){
        return {
            meta: {
              title: 'Apps Summary',
              desc:'You can reach your organization apps summary insights here.'
            },
            loading:null,
            activeList: [],
            filter: {
              TeamId:null,
              EmployeeId:null,
              ProjectId:null,
              ProjectTaskId:null,
              DateFilter:1,
              StartDate:null,
              EndDate:null
            }
        }
    },
    async mounted() {
      if(!this.teams)
      {
        await this.$store.dispatch("getTeams");
      }
      
      await this.$store.dispatch("getSubscription");

      this.$setMeta(this.meta);

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      await this.getData();
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("appsSummary", this.filter);
        this.loading = null;
        
        this.activeList = [];
        if(this.currentTeams.length > 0)
        {
          this.activeList.push(this.currentTeams[0].ID);
        }
        

      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("appsSummaryExport", this.filter);
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      productivityBadgeBg(percentage) {
        if(percentage < 60)
        {
          return "red";
        }
        else if(percentage < 80)
        {
          return "orange";
        }
        return "green"
      },
      toggleActiveList(item) {
        if(this.activeList.some(x => x == item))
        {
          this.activeList = [];
        }
        else
        {
          this.activeList = [];
          this.activeList.push(item);
        }
      },
    },
    computed: {
      account() {
          return this.$store.getters.getAccount;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
      currentTeams() {
        var result = [];
        for (let i = 0; i < this.appsSummary.Items.length; i++) {
          const element = this.appsSummary.Items[i];
          if(!result.some(x => x.ID == element.OrganizationApp.TeamId))
          {
            result.push(this.teams.Items.find(x => x.ID == element.OrganizationApp.TeamId));
          }
        }

        // this.activeList = [];
        // if(result.length > 0)
        // {
        //   this.activeList.push(result[0].ID);
        // }
        return result;
      },
      appsSummary() {
        return this.$store.getters.getAppsSummary;
      },
      teams() {
        return this.$store.getters.getTeams;
      },
      employees() {
        return this.$store.getters.getEmployees;
      }
    },
    watch: {
      'filter.StartDate'() {
        this.getData();
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }
        this.getData();
      },
      'filter.EmployeeId'() {
        this.getData();
      },
      'filter.ProjectId'() {
        this.filter.ProjectTaskId = null;
        this.getData();
      },
      'filter.ProjectTaskId'() {
        this.getData();
      },
    },
}
</script>
